import React, { useContext } from "react"
import ContentGeneral from "../components/ContentGeneral"
import { AppContext } from "../contexts/AppContext"

/**
 * Here selected country is just used as a weay to trigger client side rendering so that css works well
 */
const GeneralPage = (props) => {
  let { selectedCountry } = useContext(AppContext)
  return <>{selectedCountry && <ContentGeneral />}</>
}

export default GeneralPage

/*
export const pageQuery = graphql`
query MyQuery {
  allMongodbStrapiPages {
    edges {
      node {
        id
        mongodb_id
        identifier
        type
        createdAt
        updatedAt
        station
      }
    }
  }
}
`
*/
