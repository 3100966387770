import React from "react"


/**
 * This is a general page that would handle those URLs for which there is no existing pages. 
 * This will generally be used to create links for individual stations/artists etc. 
 * Configuration in backend would be used to return to actual page
 */

 const ContentGeneral = () => {
    return <div>This is general page</div>
}

export default ContentGeneral